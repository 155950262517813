import React, { useEffect, useState } from 'react';
import { Box, Collapse, AlertTitle, Alert, Typography, IconButton, Button } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';

const CollapseAlertBox = ({ openCollapseAlert, onData }) => {
    const [openAlert, setOpenAlert] = useState(false);

    useEffect(() => {
        if (openCollapseAlert) {
            setOpenAlert(true);
        }
    }, [openCollapseAlert]);

    const handleOnClose = (openUrl = false) => {
        console.log("onclose called");
        setOpenAlert(false);
        if (onData) onData(false);
        if (openUrl) {
            // window.open('https://example.com', '_blank');
        }
    };

    /**
     * Onclick funtion for the handle Pointer Button
     */
    const handlePointerSyatemClick =()=>{
        window.open('https://pointsystem.race.foundation/', '_blank')
        handleOnClose(true);
    }


    return (
        <Box className="points-system-wrapper">
            <Box className="collapse-alert-box">
                <Collapse in={openAlert}>
                    <Alert
                        severity="info"
                        icon={false} // Remove default icon
                        action={
                            <Box display="flex" alignItems="center">
                               <Button
                                    color="inherit"
                                    onClick={handlePointerSyatemClick}
                                    variant="outlined"
                                    className="btn-rounded bnt-black-outline"
                                >
                                    Enter Points System
                                </Button>
                                {/* Close button */}
                                <Box ml={1.5} class="close-btn">
                                    <IconButton
                                        color="inherit"
                                        onClick={() => handleOnClose(false)}
                                        aria-label="close"
                                        size="small"
                                    >
                                        <CloseIcon />
                                    </IconButton>
                                </Box>
                            </Box>
                        }
                        className="collapse-alert-body"
                    >
                        <AlertTitle>Join the RACE Points System!
                       </AlertTitle>
                        <Typography>
                        Earn 10x points in the first month. Join now and start collecting points!
                        </Typography>
                    </Alert>
                </Collapse>
            </Box>
        </Box>
    );
};

export default CollapseAlertBox;
